@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Effra';
  src: local('EffraMedium'), url('./fonts/Effra-Medium.ttf') format('truetype');
}

*{
  font-family: 'Effra';
  transition: 0.4s;
}

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

body{
    background-color:#f5f7f9;
}

button:hover{
  transition: all 200ms ease-in-out;
  /* transition-delay: 150ms; */
  transform: scale(1.02);
  transform: scale(1.02);
}